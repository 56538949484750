import { useCallback, useState } from "react";
import { Button, ContentBox, ContentWrapper, Input, InputRow, Preview, Result, ToggleButton } from "./styles";
import { rollDice } from "./utils";

type DiceRollerOutput = {
  result: number | undefined;
  roll: (e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  rolls: number[][];
}

function useDiceRoller(input: string): DiceRollerOutput {
  const [currentResult, setCurrentResult] = useState<number | undefined>(undefined);
  const [rolls, setRolls] = useState<number[][]>([]);

  const roll = useCallback((e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    if (input) {
      const { result, rolls } = rollDice(input);
      setCurrentResult(result);
      setRolls(rolls);
    } else {
      setCurrentResult(undefined);
      setRolls([]);
    }
  }, [input]);

  return { result: currentResult, roll, rolls };
}

function DiceRoller() {
  const [input, setInput] = useState("");
  const [showRolls, setShowRolls] = useState(false);

  const { result, roll, rolls } = useDiceRoller(input);

  const toggleShowRolls = useCallback(() => setShowRolls(!showRolls), [showRolls]);

  return (
    <ContentWrapper>
      <ContentBox>
        <InputRow onSubmit={roll}>
          <Input value={input} onChange={(e) => setInput(e.target?.value)} />
          <Button onClick={(e) => roll}>Roll</Button>
        </InputRow>
        {result !== undefined && (
          <Preview>
            <ToggleButton onClick={toggleShowRolls}>{showRolls ? "▼ Hide" : "► Show"} rolls</ToggleButton>
            <div style={{ marginLeft: "16px" }}>
              {showRolls && rolls.map((rollResult, i) => (
                <span>d{rollResult[0]}: {rollResult[1]}{i !== rolls.length - 1 ? ", " : ""}</span>
              ))}
            </div>
          </Preview>
        )}
        {result !== undefined && <Result>{result}</Result>}
      </ContentBox>
    </ContentWrapper>
  );
}

export default DiceRoller;
