import { BreadcrumbLink, BreadcrumbText } from "./styles";

export type RouteCrumb = {
  path: string;
  title?: string;
}

type Props = {
  routes?: RouteCrumb[];
}

const Breadcrumbs = ({ routes = [] }: Props) => {
  return (
    <span>
      <BreadcrumbLink to="/">Shavaun MacArthur</BreadcrumbLink>
      {routes.length ? routes.map((route, index) => (
        <>
          <BreadcrumbText>{" > "}</BreadcrumbText>
          {index === routes.length - 1
            ? <BreadcrumbText>{route.title || route.path}</BreadcrumbText>
            : <BreadcrumbLink to={route.path}>{route.title || route.path}</BreadcrumbLink>}
        </>
      )) : null}
    </span>
  );
};

export default Breadcrumbs;
