import styled from "styled-components";
import { Link } from "react-router-dom";

export const BreadcrumbText = styled.span`
  color: white;
  font-weight: bold;
`;

export const BreadcrumbLink = styled(Link)`
  color: white;
  text-decoration: none;
  font-weight: bold;
  text-decoration-color: white;

  &:hover {
    text-decoration: underline;
  }
`;
