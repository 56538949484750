import styled from "styled-components";

export const ContentWrapper = styled.div`
  background-color: #1e7b7b;
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 10rem 1.5rem 1.5rem;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ContentBox = styled.div`
  max-width: 30rem;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const InputRow = styled.form`
  display: flex;
  flex-direction: row;
  width: 100%;

  @media (max-width: 500px) {
    flex-direction: column;
  }
`;

export const Input = styled.input`
  flex: 1;
  border: none;
  outline: none;
  font-size: 2rem;
  border-radius: 1.5rem;
  padding: 0.5rem 1.25rem;
  background-color: rgba(255, 255, 255, 0.2);
  color: white;
  min-width: 0;
`;

export const Button = styled.button`
  border-radius: 26px;
  min-width: 53px;
  min-height: 53px;
  max-height: 53px;
  max-width: 53px;
  border: none;
  outline: none;
  cursor: pointer;
  margin-left: 0.5rem;
  background-color: #124f4f;
  color: white;

  @media (max-width: 500px) {
    margin-left: 0;
    margin-top: 0.5rem;
    width: 100%;
    max-width: 100%;
    font-size: 1.5rem;
  }
`;

export const Preview = styled.div`
  padding: 0.5rem;
`;

export const Result = styled.div`
  padding: 1rem;
  text-align: center;
  font-size: 5rem;
  border-radius: 1rem;
`;

export const ToggleButton = styled.button`
  outline: none;
  border: none;
  color: white;
  background-color: transparent;
  padding: 0;
  margin: 0;
  cursor: pointer;
  margin-bottom: 0.25rem;
`;
