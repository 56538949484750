import styled from "styled-components";
import Layout from "../../components/Layout";

const ContentWrapper = styled.div`
  padding: 1.5rem;
`;

function Main() {
  return (
    <Layout>
      <ContentWrapper>
        <h2>Stuff</h2>
        <ul>
          <li><a href="/dnd-tools">D&D Tools</a></li>
        </ul>
      </ContentWrapper>
    </Layout>
  );
}

export default Main;
