import { styled } from "styled-components";
import Layout from "../../components/Layout";

const ContentWrapper = styled.div`
  padding: 1.5rem;
`;

function DndTools() {
  return (
    <Layout routes={[{ path: "/dnd-tools", title: "D&D Tools" }]}>
      <ContentWrapper>
        <h2>D&D Tools</h2>
        <ul>
          <li><a href="/dnd-tools/dice">Dice Roller</a></li>
        </ul>
      </ContentWrapper>
    </Layout>
  );
}

export default DndTools;
