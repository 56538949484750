
export enum Sign {
  Plus = "+",
  Minus = "-",
  Multiply = "*",
  Divide = "/",
}

export enum DropType {
  High = "H",
  Low = "L",
}

export type DiceConfig = {
  numDice: number;
  numSides: number;
  sign: Sign;
  numDrop?: number;
  dropType?: DropType;
};

export type DiceRollConfig = {
  diceConfigs: DiceConfig[];
  modifier: number;
  multiplier: number;
};

export const defaultDiceRollConfig: DiceRollConfig = {
  diceConfigs: [],
  modifier: 0,
  multiplier: 1,
};

export function convertDiceNotationToConfig(diceNotation: string): DiceRollConfig {
  const cleanInput = diceNotation.trim().replace(/\s+/g, "");
  const parts = cleanInput.split(/([+\-*/])/);

  const diceConfigs: DiceConfig[] = [];
  let modifier: number = 0;
  let multiplier: number = 1;
  let currentSign: Sign = Sign.Plus;

  parts.forEach((part, i) => {
    // Test for dice roll
    if (part.match(/^\d+[d]\d+$/i)) {
      const diceConfig = {
        numDice: 0,
        numSides: 0,
        sign: currentSign,
      };

      const diceParts = part.split(/[d]/i);
      diceConfig.numDice = parseInt(diceParts[0], 10);
      diceConfig.numSides = parseInt(diceParts[1], 10);

      diceConfigs.push(diceConfig);
    }

    // Test for sign
    else if (part.match(/^[+\-*/]$/)) {
      currentSign = part as Sign;
    }

    // Test for drop
    else if (part.match(/^\d*[HL]$/i) && currentSign === "-") {
      const diceConfig = diceConfigs[diceConfigs.length - 1];
      const dropParts = part.split(/(\d+)([HL])/i).filter(Boolean);
      if (dropParts.length === 1) {
        diceConfig.numDrop = 1;
        diceConfig.dropType = dropParts[0] as DropType;
      } else {
        diceConfig.numDrop = parseInt(dropParts[0], 10);
        diceConfig.dropType = dropParts[1] as DropType;
      }
    }

    // Test for modifier
    else if (part.match(/^\d+$/)) {
      if (currentSign === "*") {
        multiplier = parseInt(part, 10);
      } else if (currentSign === "/") {
        multiplier = 1 / parseInt(part, 10);
      } else {
        modifier += parseInt(`${currentSign}${part}`, 10);
      }
    }
  });

  return {
    diceConfigs,
    modifier,
    multiplier,
  };
}

export type DiceRollResult = {
  result: number;
  rolls: number[][];
};

export function rollDice(input: string): DiceRollResult {
  const {
    diceConfigs,
    modifier,
    multiplier,
  } = convertDiceNotationToConfig(input);

  let result = 0;
  let individualRolls: number[][] = [];

  diceConfigs.forEach(diceConfig => {
    let rolls = [];
    for (let i = 0; i < diceConfig.numDice; i++) {
      const roll = Math.floor(Math.random() * diceConfig.numSides) + 1;
      rolls.push(roll);
      individualRolls.push([diceConfig.numSides, roll]);
    }

    let currentResult = 0;

    if (diceConfig.dropType) {
      if (diceConfig.dropType === "H") {
        rolls = rolls.sort((a, b) => b - a).slice(diceConfig.numDrop);
      } else {
        rolls = rolls.sort((a, b) => a - b).slice(diceConfig.numDrop);
      }
    }

    currentResult = rolls.reduce((acc, val) => acc + val, 0);

    const sign = diceConfig.sign;
    switch (sign) {
      case "+":
        result += currentResult;
        break;
      case "-":
        result -= currentResult;
        break;
      case "*":
        result *= currentResult;
        break;
      case "/":
        result /= currentResult;
        break;
      default:
        throw new Error(`Invalid sign: ${sign}`);
    }
  });

  if (modifier) {
    result += modifier;
  }

  if (multiplier) {
    result *= multiplier;
  }

  return {
    rolls: individualRolls,
    result,
  };
}
