import { useRouteError } from "react-router-dom";
import styled from "styled-components";
import Layout from "../../components/Layout";

const ContentWrapper = styled.div`
  padding: 1.5rem;
  text-align: center;
`;

function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  return (
    <Layout>
      <ContentWrapper>
        <h1>Oops!</h1>
        <p>An unexpected error has occurred.</p>
        <p style={{ color: "grey" }}>
          {/* @ts-ignore */}
          <i>{error.statusText || error.message}</i>
        </p>
      </ContentWrapper>
    </Layout>
  );
}

export default ErrorPage;
