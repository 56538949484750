import Breadcrumbs, { RouteCrumb } from "../Breadcrumbs";
import { Content, Footer, FooterItem, Header, SiteContent, SiteRoot } from "./styles";

type Props = {
  children: React.ReactNode;
  routes?: RouteCrumb[];
}

function Layout({ children, routes }: Props) {
  return (
    <SiteRoot>
      <Header>
        <Breadcrumbs routes={routes} />
      </Header>
      <SiteContent>
        <Content>
          {children}
        </Content>
        <Footer>
          <FooterItem>
            Copyright © 2023 Shavaun MacArthur
          </FooterItem>
        </Footer>
      </SiteContent>
    </SiteRoot>
  );
}

export default Layout;
