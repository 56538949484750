import styled from 'styled-components';

export const SiteRoot = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
`;

export const SiteContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
`;

export const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const Header = styled.header`
  width: 100%;
  padding: 1.5rem;
  background: rgb(18, 18, 18);
  color: white;
`;

export const Footer = styled.footer`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0;
  vertical-align: baseline;
`;

export const FooterItem = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
  border-top: 1px solid rgb(233, 233, 233);
  padding: 1em 0px 2em;
  margin: 0px 1.5em;
  width: 100%;
`;
